
import { defineComponent, ref } from 'vue';
import BusinessClientDetails from '@/components/BusinessClients/BusinessClientDetails.vue';
import BusinessClientHeader from '@/components/BusinessClients/BusinessClientHeader.vue';
import MobileFooterActions from '@/components/MobileFooterActions.vue';
import swal from 'sweetalert2';
import { BusinessClient, ClientContactError } from '@/models/BusinessClients';
import { api } from '@/services/Api';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import Q from 'q';

export default defineComponent({
    name: 'BusinessClientNew',
    components: {
        BusinessClientDetails,
        BusinessClientHeader,
        MobileFooterActions
    },
    async setup() {
        const { t } = useI18n();
        const clientContactError = ref<ClientContactError[]>([]);
        const errorsClientDetails = ref<string[]>([]);
        const businessClient = ref<BusinessClient>(new BusinessClient());
        const activeBtn = ref(1);

        function back() {
            router.push({ name: 'businessClients' });
        }
        async function updateBusinessClient() {
            swal.showLoading();
            clientContactError.value = [];
            errorsClientDetails.value = [];
            if (businessClient.value.searchSystem != null) {
                businessClient.value.searchSystem = Number(businessClient.value.searchSystem);
            }
            if (!businessClient.value.phone2) {
                businessClient.value.phone2 = null;
            }
            const saveBusinessClientPromise = api.saveBusinessClient(businessClient.value);
            await Q.delay(400);
            const saveBusinessClientResponse = await saveBusinessClientPromise;
            if (saveBusinessClientResponse.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: saveBusinessClientResponse.errorMessage });
                return;
            }
            if (saveBusinessClientResponse.data) {
                let errorAlertWas = false;
                if (saveBusinessClientResponse.data.errorsClientDetails && saveBusinessClientResponse.data.errorsClientDetails.length) {
                    errorsClientDetails.value = saveBusinessClientResponse.data.errorsClientDetails;
                    let errorMsg = '';
                    if (saveBusinessClientResponse.data.errorsClientDetails.includes('email')) {
                        errorMsg = t('businessClient.alert.incorrect-email');
                    } else if (saveBusinessClientResponse.data.errorsClientDetails.includes('phone1')) {
                        errorMsg = t('businessClient.alert.incorrect-phone1');
                    } else if (saveBusinessClientResponse.data.errorsClientDetails.includes('phone2')) {
                        errorMsg = t('businessClient.alert.incorrect-phone2');
                    }
                    swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: errorMsg });
                    errorAlertWas = true;
                }
                if (saveBusinessClientResponse.data.clientContactErrors && saveBusinessClientResponse.data.clientContactErrors.length) {
                    clientContactError.value = saveBusinessClientResponse.data.clientContactErrors;
                    if (!errorAlertWas) {
                        swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: t('businessClient.alert.incorrect-contact-data') });
                    }
                    errorAlertWas = true;
                }
                if (errorAlertWas) {
                    return;
                }
                clientContactError.value = [];
                errorsClientDetails.value = [];
                router.push({ name: 'businessClientView', params: { id: saveBusinessClientResponse.data.businessClientId, page: 1 } });
                await swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Business Client updated',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        }
        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'warning',
                    title: t('alert.mandatoryfields'),
                    confirmButtonText: t('button.close')
                });
            } else {
                updateBusinessClient();
            }
        };

        function setActiveBtn(activeBtnValue: number) {
            activeBtn.value = activeBtnValue;
        }
        return { businessClient, back, updateBusinessClient, submitForm, clientContactError, errorsClientDetails, setActiveBtn, activeBtn };
    }
});
